import Axios from 'axios';

const get = async (props) => {
    const url = props
    // let _r = await fetch(url).then(response=>response.ok?response.json():Promise.reject(response)).then(json=>json).catch(err=> console.log(err))
    let result = await Axios({withCredentials: 'include', method: 'get',url: url})
    return(
        result.data
    )
}

export default get