// COMPONETISAR LAS TABLAS
// RECIBEN LOS HEADS
// RECIBEN LAS FILAS DE DATOS
// RECIBEN LOS BOTONES DE ACCIONES DEL FOOTER

import React from 'react'

const dynamicColumns=(columnsNumber)=>{
    return {
        "--columns":columnsNumber
    }
}

const TableProduct = ({head,body,error}) => {

  return (
    <div className='table'>
        <section style={dynamicColumns(head.length)} className='table-head table-head2'>
                {head.map((each,index)=>{
                    return (
                        <span className='table-head-cell' key={index}>{each}</span>
                    )
                })}
        </section>
        <section style={dynamicColumns(head.length)} className='table-body'>
            {
                body?
                    body.map((row,index)=>{
                        return (
                            <div key={index} className='table-body-row2 table-body-row' style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#eeeeee' }}>
                                {[...Object.values(row)].map((cell,index)=>{
                                return <span key={index} className='table-body-cell2 text-ellipsis text-jn px-3'>
                                            {cell}
                                        </span>
                                })}
                            </div>
                        )
                    })
                :
                error? <><p>{error.message}</p> </>:<> <p>No hay datos para mostrar</p></>
                }
        </section>
        {/* <thead>
            <tr>
                {head.map((each,index)=>{
                    return (
                        <th key={index}>{each}</th>
                    )
                })}
            </tr>
        </thead>
        <tbody>
            {body.map((row,index)=>{
                return (
                    <tr key={index}>
                        {[...Object.values(row)].map((cell,index)=>{
                           return <td key={index}>
                                <div>
                                    {cell}
                                </div>
                                
                            </td>
                        })}
                    </tr>
                )
            })}

        </tbody> */}
    </div>
  )
}

export default TableProduct