import { Navigate, Outlet } from "react-router-dom";
import { UserAuthContext } from "../../contexts/authContext";
import { USER } from "../../routes/paths";

const PublicRouter = () => {
    console.log(1000)
    const{isAuthenticated} = UserAuthContext();

    if(isAuthenticated) {
        console.log(1)
        return <Navigate to={USER}/>
    }
    console.log(2)

    return(
        <div>
            <Outlet />
        </div>
    )
}

export default PublicRouter