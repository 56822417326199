import { useEffect } from "react"
import { useRef } from "react"
import alertsvg from "../../assets/images/intencol/alert.svg"


const AddClientForm = ({formState,editable}) => {
    let {get,set,errors,clear}=formState

    let $form=useRef(null)
    let $fullname=useRef(null)
    let $username=useRef(null)
    let $password=useRef(null)

    useEffect(()=>{
      if(editable){
        $fullname.current.disabled=false
        $username.current.disabled=false
        $password.current.disabled=false
      }
      if(!editable){
        $fullname.current.disabled=true
        $username.current.disabled=true
        $password.current.disabled=true
      }

    },[editable])

    useEffect(()=>$form.current.reset(),[clear])

  return (
    <form className='form-addclient' ref={$form}>
        <span>
          <label htmlFor="Fullname">Nombre y Apellido:</label>
          <input type="text" id="Fullname" autoComplete="off" ref={$fullname}  defaultValue={get.fullname} onChange={(e)=>{
              let {target:{value}}=e
              let {fullname,...props}=get

              set({fullname:value,...props})
          }}
          />
          <img style={
            errors.fullname?  
              {
                display:"flex"
              }
            :
              {
                display:"none"
              }
          } src={alertsvg} alt=""/>
        </span>
        <span>
            <label htmlFor="IdentityDocumentSerial">Nombre de usuario:</label>
            <input type="text" id="IdentityDocumentSerial" autoComplete="off" ref={$username} defaultValue={get.username} onChange={(e)=>{
              let {target:{value}}=e
              let {username,...props}=get

              set({username:value,...props})
          }}/>
            <img style={
              errors.username?  
                {
                  display:"flex"
                }
              :
                {
                  display:"none"
                }
              } src={alertsvg} alt=""/>
        </span>
        <span>
            <label htmlFor="PhoneNumber">Contraseña:</label>
            <input type="password" id="PhoneNumber" autoComplete="off" ref={$password} defaultValue={get.password} onChange={(e)=>{
              let {target:{value}}=e
              let {password,...props}=get

              set({password:value,...props})
          }}/>
            <img style={
              errors.password?  
                {
                  display:"flex"
                }
              :
                {
                  display:"none"
                }
              } src={alertsvg} alt=""/>
        </span>
    </form>
  )
}

export default  AddClientForm