import ModalBackground from "../../components/modal/modal_background"
import useModal from "../../hooks/useModal"
import newProduct from "../../assets/images/inicia/new-product.svg"
import ModalForm from "../../components/modal/modal_form"
import AddClientForm from "../../components/form/add_client_form"
import { useEffect, useState} from "react"
import Loader from "../../components/modal/loader"
import Table from "../../components/container/table"
import { Link, Navigate, useNavigate} from "react-router-dom"
import { PRODUCT, USERS } from "../../routes/paths"
import useFetch from "../../hooks/useFetch"
import { PRODUCT_ENDPOINTS, USER_ENDPOINTS } from "../../services/api.config"
import post from "../../helpers/post"
import { replaceEmptySpacesForHyphens } from "../../utils/formatText"
import ESTranslation from "../../ES/ESTranslation"
import AddProductForm from "../../components/form/add_product_form"
import get from "../../helpers/get"
import {ReactComponent as Search } from "../../assets/images/intencol/search.svg";


const ADD_CLIENT_BUTTON_STYLES={
    marginLeft:'auto',
    cursor:'pointer'
}
const Height={
    height:'90%',
}

const EDIT_CLIENT_BUTTON_STYLES={
    color:'#00ade5',
    cursor:'pointer'
}

const preprocessTableBodyData=(data)=>{
    data=data || []
    const actionButtons=(id,slug)=>{

        return (
            <>
                <Link style={EDIT_CLIENT_BUTTON_STYLES} state={{id}} to={`${PRODUCT}/${id}`} >
                    Ver o editar
                </Link>
            </>
        )
    }
    let _processedData=[...data].map(row=>{
        let {id,name,code,stock}=row
            let  _slug=replaceEmptySpacesForHyphens(name).toLowerCase()
                
            let _newData={
                code,
                name,
                stock,
                actions:actionButtons(id,_slug)
            }

            return _newData
    })
        
    return _processedData
        
}

const GETALL =(setClients)=>{
    get(PRODUCT_ENDPOINTS.getAll)
    .then((response) => {
        setClients(response.data)
    })
}
const Product=()=>{
    let _initialFormState={
        name:'',
        code:'',
        stock:'',
    }
    let _initialFormErrorState={
        name:false,
        code:false,
        stock:false,
    }
    let navigate = useNavigate();
    let [isActiveAddClientModal,activateAddClientModal,desactivateAddClientModal]=useModal(false)
    let {data:fetchedClients,loading:loadingFetchedClients,error:fetchedClientsErrors}=useFetch(PRODUCT_ENDPOINTS.getAll())
    let [clientForm,setClientForm]=useState(_initialFormState)
    let [clientFormError,setClientFormError]=useState(_initialFormErrorState)
    let [toggleFormClear,setToggleFormClear]=useState(false)
    let [clients,setClients]=useState(null)
    let [processedClients,setProcessedClients]=useState(null)
    let [productTable,setProductTable]=useState(null)
    let [loadingClientCreation,setLoadingClientCreation]=useState(false)
    let [MessageNew,setMessageNew]=useState("")
    const [typingTimeout, setTypingTimeout] = useState(null);
    

        useEffect(()=>{
            if(fetchedClients)setClients(fetchedClients.data)
            if(fetchedClients)setProductTable(fetchedClients.data)
        },[fetchedClients])    

        useEffect(()=>{
            if(clients){
                let _processedData=preprocessTableBodyData(clients)
                    setProcessedClients(_processedData)  
            }
        },[clients])

    const handleSave=async()=>{
        let _errors={}

        for (const key in clientForm) {
            if (Object.hasOwnProperty.call(clientForm, key)) {
                const field = clientForm[key];
                // Fields validation
                field.trim()===''?_errors[key]=true:_errors[key]=false
            }
        }
        setClientFormError(_errors)
        let _anyError=[...new Set([...Object.values(_errors)])]
            if(_anyError.length===1 && _anyError[0]===false){
                //Post
                let _data={
                    name:clientForm.name,
                    code:clientForm.code,
                    stock:parseFloat(clientForm.stock),
                }
                let _createClientPromise=post(PRODUCT_ENDPOINTS.create(),_data)
                    setLoadingClientCreation(true)
                    _createClientPromise
                    .then(response=>{
                        if(response.message==="all is fine"){
                            let prueba = get(PRODUCT_ENDPOINTS.getAll())
                            prueba
                            .then((response) => {
                                    setClients(response.data);
                                    setClientForm(_initialFormState)
                                    setToggleFormClear(prevState=>!prevState)
                                    desactivateAddClientModal()
                                })
                        }
                        if(response.message === 'error code'){
                            setMessageNew('El codigo se repite')
                        }
                        if(response.message === 'empty data'){
                            setMessageNew('Hay Dato vacío')
                        }
                        if(response.message === 'user exist'){
                            setMessageNew('El Usuario ya esta en uso')
                        }
                        if(response.message === 'username'){
                            setMessageNew('Nombre de usuario solo letra y numeros')
                        }
                        if(response.message === 'password'){
                            setMessageNew('La contraseña debe tener minimo 7 Caracteres')
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                        desactivateAddClientModal()
                    })
                    .finally(()=>{
                        setLoadingClientCreation(false)
                    })

            }
            // Error filling the fields (message)
    }

    const handleCancelation=()=>{
        setClientForm(_initialFormState)
        setToggleFormClear(prevState=>!prevState)
    }
    const handlesearch=(event)=>{
            const value = event.target.value;
            if (typingTimeout) {
              clearTimeout(typingTimeout);
            }
            setTypingTimeout(setTimeout(() => {
                filter(value);
            }, 500));
        
        // filter(search)
    }
    const filter=(search)=>{
        var resultadosBusqueda=productTable.filter((elemento)=>{
            if(elemento.name.toString().toLowerCase().includes(search.toLowerCase())
            || elemento.code.toString().toLowerCase().includes(search.toLowerCase())
            ){
              return elemento;
            }
          });
          setClients(resultadosBusqueda);

    }


    return (
        <>
            <div className=" absolute top-0 right-0 w-full h-24 flex">
                <div className="w-fit h-fit my-auto mr-auto ml-72 shadow flex rounded-lg border-solid">
                    <input type="text" placeholder="Buscar Producto" onChange={handlesearch} className="text-in px-2 py-1 w-56 text-text" id="search"/>
                    <label for="search" className="m-auto">
                        <Search className="w-4 h-4 mr-2 my-auto"/>
                    </label>
                </div>
            </div>
            <div className="container">{/*componente container*/}
                <div className="content-container box-shadow clients-table">{/*componente container*/}
                    <section className="clients-table-body-section"  style={Height} >
                        <Table head={ESTranslation.TABLE_HEAD.product_page} body={processedClients} error={fetchedClientsErrors} columns="20% 50% 15% 15%"/>
                    </section>
                    <section className="clients-table-footer-section">
                        <div>
                            <button style={ADD_CLIENT_BUTTON_STYLES} onClick={activateAddClientModal}>
                                <span>
                                    <img src={newProduct} alt=""/>
                                </span>
                                Agregar Producto
                            </button>
                        </div>
                    </section>
                </div>
            </div>

            <ModalBackground isActive={isActiveAddClientModal} closeByBackground={desactivateAddClientModal}>
                <ModalForm title={ESTranslation.FORM.add_product.title} closeByButton={desactivateAddClientModal} handleSave={handleSave} handleCancelation={handleCancelation} alert={MessageNew}>
                    <AddProductForm formState={{get:clientForm,set:setClientForm,errors:clientFormError,clear:toggleFormClear}} editable={true}/>
                </ModalForm>   
            </ModalBackground> 

            <Loader isActive={loadingFetchedClients}/>
            <Loader isActive={loadingClientCreation}/>
                
        </>
    )

}

export default Product