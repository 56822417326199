import { UserAuthContext } from "../../contexts/authContext";
import get from '../../helpers/get';

const Logout = () => {
    const {logout} = UserAuthContext();
    get('http://localhost:8180/api/logout').then(r =>{
        console.log(r.message)
        if(r.message === 'all fine'){
            logout()
        }
    })
    return null
}
export default Logout