export const PORT=5893
export const ROOT='https://api.intencol.com'
// export const ROOT='http://localhost:5893'


export const USER_ENDPOINTS={
    getAll:()=>`${ROOT}/api/user`,
    getOne:(id)=>`${ROOT}/api/user/${id}`,
    Login:()=>`${ROOT}/api/user/login`,
    create:()=>`${ROOT}/api/user`,
    update:(id)=>`${ROOT}/api/user/${id}`,
    delete:(id)=>`${ROOT}/api/user/${id}`
}
export const PRODUCT_ENDPOINTS={
    getAll:()=>`${ROOT}/api/product`,
    getOne:(id)=>`${ROOT}/api/product/${id}`,
    create:()=>`${ROOT}/api/product`,
    update:(id)=>`${ROOT}/api/product/${id}`,
    updateStock:(id)=>`${ROOT}/api/product/stock/${id}`,
    delete:(id)=>`${ROOT}/api/product/${id}`
}
export const BILL_ENDPOINTS={
    getAll:()=>`${ROOT}/api/bill`,
    getOpen:()=>`${ROOT}/api/bill/open`,
    getClased:()=>`${ROOT}/api/bill/clased`,
    getOne:(id)=>`${ROOT}/api/bill/${id}`,
    create:()=>`${ROOT}/api/bill`,
    update:(id)=>`${ROOT}/api/bill/${id}`,
    clased:(id)=>`${ROOT}/api/bill/closed/${id}`,
    createProduct:()=>`${ROOT}/api/bill/product`,
    updateProduct:(id)=>`${ROOT}/api/bill/product/${id}`,
    delateProduct:(id)=>`${ROOT}/api/bill/product/${id}`
}
export const LOGIN_ENDPOINTS={
    login:()=>`${ROOT}/api/signin`,
    logout:()=>`${ROOT}/api/logout`,
}