import Axios from 'axios';

const adelete = async (url) => {
    const config = {
        url: url,
        method: 'delete',
        withCredentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    }
    // let _r = await fetch(url).then(response=>response.ok?response.json():Promise.reject(response)).then(json=>json).catch(err=> console.log(err))
    let result = await Axios(config)
    return(
        result.data
    )
}

export default adelete