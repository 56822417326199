import { Link, Navigate, redirect, useLocation, useNavigate, useParams } from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import AddClientForm from "../../components/form/add_client_form";
import ModalAlert from "../../components/modal/modal_alert";
import ModalBackground from "../../components/modal/modal_background";
import useEdition from "../../hooks/useEdition";
import useModal from "../../hooks/useModal";
import returntosvg from "../../assets/images/inicia/arrow-orange.svg"
import useFetch from "../../hooks/useFetch";
import Loader from "../../components/modal/loader";
import { USER_ENDPOINTS } from "../../services/api.config";
import put from "../../helpers/put";
import adelete from "../../helpers/adelete";
import { PRODUCT, USERS } from "../../routes/paths";
const DELETE_CLIENT_BUTTON_STYLES={
    backgroundColor:"#FF5959",
    width:"95%"
}
const EDIT_CLIENT_BUTTONS_STYLES={
    backgroundColor:"#00ade5",
    width:"43%",
    marginLeft:"auto",
    marginRight:"auto"
}


const NotEditionModeActions=(id,slug)=>{

    return (
        <>
        {/* <Link style={MOVEMENTS_CLIENT_BUTTON_STYLES} state={{id}} to={`${CLIENT}/${slug}/transactions`} >
                Ver movimientos
        </Link> */}
        </>
    )
}
const EditionModeActions=(update,cancel)=>{

    const handleCancelation=()=>cancel()
    const handleSave=()=>update()

    return (
        <>
            <button style={EDIT_CLIENT_BUTTONS_STYLES} onClick={handleCancelation}>
                Cancelar
            </button>
            <button style={EDIT_CLIENT_BUTTONS_STYLES} onClick={handleSave}>
                Guardar
            </button>
        </>
    )
}

const UsersDetail=()=>{
    const {slug}=useParams();
    const {id:clientId}=useLocation().state;
    let _initialFormState={
        fullname:'',
        username:'',
        password:'',
    }
    let _initialFormErrorState={
        fullname:false,
        username:false,
        password:false,
    }
    let navigate = useNavigate();
    let [isActiveEdition,activateEdition,desactivateEdition]=useEdition(false)
    let [isActiveDeleteAlert,activateDeleteAlert,desactivateDeleteAlert]=useModal(false)
    let [clientForm,setClientForm]=useState(_initialFormState)
    let {data:fetchedDetails,loading:loadingFetchedDetails}=useFetch(`${USER_ENDPOINTS.getOne(clientId)}`)
    let [clientDetails,setClientDetails]=useState(null)
    let [loadingClientUpdate,setLoadingClientUpdate]=useState(false)
    let [clientFormError,setClientFormError]=useState(_initialFormErrorState)
    // Slider
    let $productsSlider=useRef(null)
    let $prevSliderButton=useRef(null)


    useEffect(()=>{
        if(fetchedDetails){
            let {name,username,password}=fetchedDetails.data
            let _initialFormState={
                fullname:name,
                username:username,
                password:password,
            }

            setClientForm(_initialFormState)
            setClientDetails(fetchedDetails.data)
        }
    },[fetchedDetails])


    const editionMode=()=>{
        let _oldState=isActiveEdition
            _oldState?desactivateEdition():activateEdition()
    }
   
    const handleSave=()=>{
            //Put
            let _data={
                name:clientForm.fullname,
                username:clientForm.username,
                password:clientForm.password,
            }

            let _updateClientPromise=put(USER_ENDPOINTS.update(clientId),_data)

                setLoadingClientUpdate(true)
                _updateClientPromise
                .then(response=>{
                        console.log(response)
                    if(response.message==="all is fine"){
                        // <redirect to="/maikel"/>
                        return navigate(USERS)
                        // desactivateEdition()
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
                .finally(()=>{
                   setLoadingClientUpdate(false)
                })
    }

    const handleCancelation=()=>desactivateDeleteAlert()
    const handleDelete=()=>{
        let _updateClientPromise=adelete(USER_ENDPOINTS.delete(clientId))
        setLoadingClientUpdate(true)
        _updateClientPromise
        .then(response=>{
            if(response.message==="all is fine"){
                return navigate(USERS)
            }
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            setLoadingClientUpdate(false)
        })
        // DELETE
        desactivateDeleteAlert()
    }
    
    return (
        <>
            <div className="client-detail-grid">
                <section className="client-info-section">
                    <p className="text-monserrat-regular">Información de usuario:</p>
                    <div className="box-shadow">
                        {loadingFetchedDetails
                            ?
                                <></>
                            :
                                <AddClientForm formState={{get:clientForm,set:setClientForm,errors:clientFormError}} editable={isActiveEdition}/>
                        }
                        <button style={isActiveEdition?{opacity:".7"}:{opacity:"1"}} onClick={editionMode}></button>
                    </div>
                </section>
                <section className="client-summary-section">
                </section>
                <section className="client-feedback-section">
                </section>
                <section className="actions-section">
                    <div>
                        {isActiveEdition?EditionModeActions(handleSave,desactivateEdition):NotEditionModeActions(clientId,slug)}
                        
                        <button style={DELETE_CLIENT_BUTTON_STYLES} onClick={activateDeleteAlert} >
                            Eliminar usuario
                        </button>
                    </div>
                </section>
                <Link to={USERS}>
                    <span>
                        <img src={returntosvg } alt=""/>
                    </span>
                        Volver a la lista de usuarios
                </Link>
            </div>

            {loadingFetchedDetails
                ?
                    <></>
                :
                clientDetails
                    ?
                        <ModalBackground isActive={isActiveDeleteAlert} closeByBackground={null}>
                            <ModalAlert title={"¡Atención!"} message={`¿Estas seguro/a que deseas eliminar a ${clientDetails.name} ?. Borrarás toda los datos e información almacenados. `} closeByButton={desactivateDeleteAlert} handleSave={handleDelete} handleCancelation={handleCancelation}/>
                        </ModalBackground>
                    :
                        <></>
            }
            
            <Loader isActive={loadingFetchedDetails}/>
            <Loader isActive={loadingClientUpdate}/>
        </>
    )
}

export default UsersDetail