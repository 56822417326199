import React, { useState } from 'react'


const ModalSearcher = ({children,title,closeByButton,alert,handlesearch,handleAddProduct}) => {
    const [typingTimeout, setTypingTimeout] = useState(null);
    const preventBubbling=(e)=>e.stopPropagation()
    const handleEnter = (e)=>{
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        setTypingTimeout(setTimeout(() => {
            let {keyCode} = e;
            if(keyCode===13){
                const elementos = document.getElementsByClassName('addProductText')[0]
                if(elementos){
                    let element = elementos.getAttribute('idproducto');
                    // console.log(parseInt(element))
                    handleAddProduct(parseInt(element))
                }
            }
        }, 700));
    }
    return (
        <div className='modal-form' onClick={(e)=>preventBubbling(e)}>
            <div className='flex'>
                <span className='modal-form-tittle'>
                    <h3>{title}</h3>
                </span>
                <input type="text" placeholder="Buscar Producto" className="rounded-lg border-solid shadow mt-2 mb-2 h-fit text-jn px-1 py-1 w-32 mr-auto ml-20" onChange={handlesearch} onKeyDown={handleEnter}/>
            </div>
            <button className='modal-form-close' onClick={closeByButton}></button>
            {children}
            <div className='mb-1'>
                <p className='text-center text-intencol2 text-base p-5'>{alert}</p>
            </div>
        </div>
    )
}

export default ModalSearcher