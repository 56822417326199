// COMPONETISAR LAS TABLAS
// RECIBEN LOS HEADS
// RECIBEN LAS FILAS DE DATOS
// RECIBEN LOS BOTONES DE ACCIONES DEL FOOTER

import React from 'react'

const dynamicColumns=(columnsNumber)=>{
    return {
        "--columns":columnsNumber
    }
}

const Table = ({head,body,error,columns}) => {
  return (
    <div className='table'>
        <section style={{gridTemplateColumns:columns}} className='table-head'>
                {head.map((each,index)=>{
                    return (
                        <span className='table-head-cell' key={index}>{each}</span>
                    )
                })}
        </section>
        <section className='table-body'>
            {
                body?
                    body.map((row,index)=>{
                        return (
                            <div key={index} style={{gridTemplateColumns:columns, backgroundColor: index % 2 === 0 ? '#fff' : '#f8f8f8'}}  className='table-body-row' >
                                {[...Object.values(row)].map((cell,index)=>{
                                return <span key={index} className='table-body-cell text-ellipsis text-jn px-3' >
                                            {cell}
                                        </span>
                                })}
                            </div>
                        )
                    })
                :
                error? <><p>{error.message}</p> </>:<> <p>No hay datos para mostrar</p></>
                }
        </section>
        {/* <thead>
            <tr>
                {head.map((each,index)=>{
                    return (
                        <th key={index}>{each}</th>
                    )
                })}
            </tr>
        </thead>
        <tbody>
            {body.map((row,index)=>{
                return (
                    <tr key={index}>
                        {[...Object.values(row)].map((cell,index)=>{
                           return <td key={index}>
                                <div>
                                    {cell}
                                </div>
                                
                            </td>
                        })}
                    </tr>
                )
            })}

        </tbody> */}
    </div>
  )
}

export default Table