import React from 'react';

const Input = ({attribute, handleChange, param}) => {
    return(
        <input
            id = {attribute.id}
            name = {attribute.name}
            placeholder = {attribute.placeholder}
            type = {attribute.type}
            autoComplete = 'off'
            onChange = {(e) => handleChange(e.target.name, e.target.value)}
            className = "text-center text-in p-2 w-full focus-visible:outline-none border-border border-2 rounded-full mt-2 border-solid font-roboto-medium text-text  text-base"
            // { param ? 'inputToValidate' : ''}
        />
    )
}

export default Input