import React from 'react'

const SaveButton=({handle})=>{
    const STYLES={
        backgroundColor:"#00ade5",
        color:"white"
    }

    return (
        <button style={STYLES} onClick={handle}>
            Agregar
        </button>
    )
}

const CancelButton=({handle})=>{
    const STYLES={
        backgroundColor:"white",
        color:"#E98819"
    }

    return (
        <button style={STYLES} onClick={handle}>
            Descartar
        </button>
    )
}


const ModalForm = ({children,title,closeByButton,handleSave,handleCancelation,alert}) => {
    const preventBubbling=(e)=>e.stopPropagation()

  return (
    <div className='modal-form' onClick={(e)=>preventBubbling(e)}>
        <span className='modal-form-tittle'>
            <h3>{title}</h3>
        </span>
        <button className='modal-form-close' onClick={closeByButton}></button>

        {children}
        <div className='mb-1'>
            <p className='text-center text-intencol2 text-base p-5'>{alert}</p>
        </div>
        <div className='modal-form-actions'>
            <CancelButton handle={handleCancelation}/>
            <SaveButton handle={handleSave}/>
        </div>
    </div>
  )
}

export default ModalForm