import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {useEffect,  useState} from "react";
import ModalAlert from "../../components/modal/modal_alert";
import ModalBackground from "../../components/modal/modal_background";
import useEdition from "../../hooks/useEdition";
import useModal from "../../hooks/useModal";
import returntosvg from "../../assets/images/inicia/arrow-orange.svg"
import useFetch from "../../hooks/useFetch";
import Loader from "../../components/modal/loader";
import { BILL_ENDPOINTS, PRODUCT_ENDPOINTS } from "../../services/api.config";
import put from "../../helpers/put";
import { BILL } from "../../routes/paths";
import adelete from "../../helpers/adelete";
import AddBillForm from "../../components/form/add_bill_form";
import Table from "../../components/container/table";
import ESTranslation from "../../ES/ESTranslation";
import post from "../../helpers/post";
import get from "../../helpers/get";
import ModalSearcher from "../../components/modal/modal_searcher";
import TableProduct from "../../components/container/table_product";
import {ReactComponent as Trashcansvg } from "../../assets/images/intencol/trash-can.svg";
import {ReactComponent as Addboton } from "../../assets/images/intencol/add.svg";
import TableBill from "../../components/container/table_bill";
const DELETE_CLIENT_BUTTON_STYLES={
    backgroundColor:"#008fc0",
    width:"95%"
}
const EDIT_CLIENT_BUTTONS_STYLES={
    backgroundColor:"#00ade5",
    width:"43%",
    marginLeft:"auto",
    marginRight:"auto"
}
const EDIT_CLIENT_BUTTON_STYLES={
    color:'#00ade5',
    cursor:'pointer'
}
const preprocessTableBodyData=(data,handleAddProduct)=>{
    data=data || []
    const actionButtons=(id)=>{
        let classww = `addProductText` 
        return (
            <>
                <button style={EDIT_CLIENT_BUTTON_STYLES} className={classww} idproducto={id} onClick={()=>{handleAddProduct(id)}}>
                    +
                </button>
            </>
        )
    }
    let _processedData=[...data].map(row=>{
            let {id,name,stock}=row
            let _newData={
                stock,
                name,
                actions:actionButtons(id)
            }
            return _newData
    })
        
    return _processedData
        
}
const preprocessTableBodyDataBill=(data,handleUpdateProduct,setEdited,handleDeleteProductB)=>{
    let edited = false
    if(data.status==="1"){
        edited = true
        setEdited(true)
    }
    data=data.product || []
    const actionButtons=(id, amount, max)=>{
        return (
            <div className="flex mx-auto">
                <p className="mx-2  my-auto py-1">({max})</p>
                <input type="number" value={amount} className="rounded-lg border-solid text-center h-fit text-intencol2 my-auto bg-white px-2 py-1 w-20" onChange={(e)=>{
                                let {target:{value}}=e
                                handleUpdateProduct(value,id)}}/>
            </div>
        )
    }
    const actionDelete=(id, name)=>{
        return (
            <div className="flex mx-auto">
                {edited?<button><Trashcansvg className="fill-intencol2 w-3 h-3" onClick={(e)=>{handleDeleteProductB(id, name)}}/></button>:''}
            </div>
        )
    }

    // let {product} = [...data]
    // console.log(data)
    let _processedData=[...data].map(row=>{
        let {id_product, name, amount, max}=row
            let _newData={
                name,
                stock:actionButtons(id_product, amount, max),
                delete: actionDelete(id_product, name)
            }

            return _newData
    })
        
    return _processedData
        
}

const NotEditionModeActions=(id,slug)=>{

    return (
        <>
        {/* <Link style={MOVEMENTS_CLIENT_BUTTON_STYLES} state={{id}} to={`${CLIENT}/${slug}/transactions`} >
                Ver movimientos
        </Link> */}
        </>
    )
}
const EditionModeActions=(update,cancel)=>{

    const handleCancelation=()=>cancel()
    const handleSave=()=>update()

    return (
        <>
            <button style={EDIT_CLIENT_BUTTONS_STYLES} onClick={handleCancelation}>
                Cancelar
            </button>
            <button style={EDIT_CLIENT_BUTTONS_STYLES} onClick={handleSave}>
                Guardar
            </button>
        </>
    )
}

const buttoneditable = (activateDeleteAlert,isActiveEdition,handleSave,desactivateEdition,clientId,slug) =>{
    return(
        <>
            {isActiveEdition?EditionModeActions(handleSave,desactivateEdition):NotEditionModeActions(clientId,slug)}
            <button style={DELETE_CLIENT_BUTTON_STYLES} onClick={activateDeleteAlert} >
                Finalizar Nota
            </button>
        </>
    )

}
const buttoneditableClient = (isActiveEdition,editionMode) =>{
    return(
        <>
            <button style={isActiveEdition?{opacity:".7"}:{opacity:"1"}} onClick={editionMode}></button>
        </>
    )

}

const BillDetail=()=>{
    const {slug}=useParams();
    const {id:clientId}=useLocation().state;
    let _initialFormState={
        name:'',
        number:''
    }
    let _initialFormErrorState={
        name:false,
        number:false,
    }
    let navigate = useNavigate();
    let [isActiveEdition,activateEdition,desactivateEdition]=useEdition(false)
    let [isActiveDeleteAlert,activateDeleteAlert,desactivateDeleteAlert]=useModal(false)
    let [isActiveAddProducto,activateAddProducto,desactivateAddProducto]=useModal(false)
    let [isActiveDeleteProducto,activateDeleteProducto,desactivateDeleteProducto]=useModal(false)
    let [clientForm,setClientForm]=useState(_initialFormState)
    let [clientFormError,setClientFormError]=useState(_initialFormErrorState)
    let {data:fetchedDetails,loading:loadingFetchedDetails}=useFetch(`${BILL_ENDPOINTS.getOne(clientId)}`)
    let [clientDetails,setClientDetails]=useState(null)
    let [loadingClientUpdate,setLoadingClientUpdate]=useState(false)
    let {data:fetchedProduct,loading:loadingFetchedProduct,error:fetchedProductErrors}=useFetch(PRODUCT_ENDPOINTS.getAll())
    let {data:fetchedBillProduct,loading:loadingFetchedBillProduct,error:fetchedBillProductErrors}=useFetch(PRODUCT_ENDPOINTS.getAll(clientId))
    let [processedProduct,setProcessedProduct]=useState(null)
    let [productTable,setProductTable]=useState(null)
    let [product,setProduct]=useState(null)
    let [processedBillProduct,setProcessedBillProduct]=useState(null)
    let [billProduct,setBillProduct]=useState(null)
    let [deleteProduct,setDeleteProduct]=useState(null)
    let [deleteIdProduct,setDeleteIdProduct]=useState(null)
    let [edited, setEdited]=useState(false)
    const [typingTimeout, setTypingTimeout] = useState(null);

    useEffect(()=>{
        if(fetchedProduct)setProduct(fetchedProduct.data)
        if(fetchedProduct)setProductTable(fetchedProduct.data)
    },[fetchedProduct])    

    useEffect(()=>{
        if(product){
            let _processedData=preprocessTableBodyData(product,handleAddProduct,)
                setProcessedProduct(_processedData)  
        }
    },[product])

    useEffect(()=>{
        if(fetchedBillProduct)setBillProduct(fetchedBillProduct.data)
    },[fetchedBillProduct])    

    useEffect(()=>{
        if(clientDetails){
            let _processedData=preprocessTableBodyDataBill(clientDetails,handleUpdateProduct,setEdited,handleDeleteProductB,edited)
                setProcessedBillProduct(_processedData)  
        }
    },[clientDetails])

    useEffect(()=>{
        if(fetchedDetails){
            let {name,number}=fetchedDetails.data
            let _initialFormState={
                name:name,
                number:number,
            }

            setClientForm(_initialFormState)
            setClientDetails(fetchedDetails.data)
        }
    },[fetchedDetails])
    const editionMode=()=>{
        let _oldState=isActiveEdition
            _oldState?desactivateEdition():activateEdition()
    }
    const handleSave=()=>{
        let _errors={}
        for (const key in clientForm) {
            if (Object.hasOwnProperty.call(clientForm, key)) {
                const field = clientForm[key];
                // Fields validation
                if(typeof field === 'string') {
                    field.trim()===''?_errors[key]=true:_errors[key]=false
                }
            }
        }

        let _anyError=[...new Set([...Object.values(_errors)])]
        if(_anyError.length===1 && _anyError[0]===false){
            //Put
            let _data={
                name:clientForm.name,
                number:clientForm.number,
            }
            let _updateClientPromise=put(BILL_ENDPOINTS.update(clientId),_data)
                setLoadingClientUpdate(true)
                _updateClientPromise
                .then(response=>{
                    if(response.message==="all is fine"){
                        let prueba = get(BILL_ENDPOINTS.getAll(clientId))
                        prueba.then((response) => {
                            setClientDetails(response.data);
                            desactivateEdition()
                        })
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
                .finally(()=>{
                   setLoadingClientUpdate(false)
                })
            }
    }
    const handleCancelation=()=>desactivateDeleteAlert()
    const handleDelete=()=>{
        let _updateClientPromise=put(BILL_ENDPOINTS.clased(clientId))
        setLoadingClientUpdate(true)
        _updateClientPromise
        .then(response=>{
            if(response.message==="all is fine"){
                return navigate(BILL)
            }
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            setLoadingClientUpdate(false)
        })
        // DELETE
        desactivateDeleteAlert()
    }
    
    const handleCancelationProduct=()=>desactivateDeleteProducto()
    const handleDeleteProduct=()=>{
        let _deleteClientPromise=adelete(BILL_ENDPOINTS.delateProduct(deleteIdProduct))
        setLoadingClientUpdate(true)
        desactivateDeleteProducto()
        _deleteClientPromise
        .then(response=>{
            let prueba = get(BILL_ENDPOINTS.getOne(clientId))
            prueba.then((response) => {
                setClientDetails(response.data);
            })
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            setLoadingClientUpdate(false)
        })
    }
    const handleAddProduct=(idProduct)=>{
        //Post
        let _data={
            id_bill:clientId,
            id_product:idProduct,
            amount:1
        }
        let _createClientPromise=post(BILL_ENDPOINTS.createProduct(),_data)
        setLoadingClientUpdate(true)
        _createClientPromise
        .then(response=>{
                console.log(response)
            if(response.message==="all is fine"){
                let prueba = get(BILL_ENDPOINTS.getOne(clientId))
                prueba.then((response) => {
                    setClientDetails(response.data);
                    desactivateEdition()
                })
            }
            if(response.message === 'empty data'){
            }
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            setLoadingClientUpdate(false)
        })
    }
    const handleUpdateProduct = (value, idBillDetail)=>{
        //Put
        let _data={
            amount:parseFloat(value)
        }
        let _updateClientPromise=put(BILL_ENDPOINTS.updateProduct(idBillDetail),_data)
        setLoadingClientUpdate(true)
        _updateClientPromise
        .then(response=>{
            let prueba = get(BILL_ENDPOINTS.getOne(clientId))
            prueba.then((response) => {
                setClientDetails(response.data);
            })
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            setLoadingClientUpdate(false)
        })
    } 
    const handleDeleteProductB=(id, name)=>{
        setDeleteProduct(name)
        setDeleteIdProduct(id)
        activateDeleteProducto()
    }
    const handlesearch=(event)=>{
            const value = event.target.value;
            if (typingTimeout) {
              clearTimeout(typingTimeout);
            }
            setTypingTimeout(setTimeout(() => {
                filter(value);
            }, 500));
        
        // filter(search)
    }
    const filter=(search)=>{
        var resultadosBusqueda=productTable.filter((elemento)=>{
            if(elemento.name.toString().toLowerCase().includes(search.toLowerCase())
            || elemento.code.toString().toLowerCase().includes(search.toLowerCase())
            ){
              return elemento;
            }
          });
          setProduct(resultadosBusqueda);

    }
    
    return (
        <>
            <div className="client-detail-grid">
                <section className="flex w-full flex-col row-start-1 row-end-3">
                    <div className="w-full relative h-fit">
                        <p className="text-sm pl-4 pb-1">Detalles de la Nota</p>
                        {edited?<Addboton className="w-4 h-4 fill-intencol3 absolute top-0 right-10 cursor-pointer"  onClick={activateAddProducto}/>:''}
                    </div>
                    <div className="box-shadow mx-2 rounded-xl flex justify-around items-center h-[90%] w-[95%]">
                        <TableBill head={ESTranslation.TABLE_HEAD.bill_product} body={processedBillProduct} error={fetchedProductErrors}/>
                    </div>
                </section>
                <section className="client-info-section">
                    <p>Información de la nota:</p>
                    <div className="box-shadow">
                        {loadingFetchedDetails
                            ?
                                <></>
                            :
                                <AddBillForm formState={{get:clientForm,set:setClientForm,errors:clientFormError}} editable={isActiveEdition}/>
                        }
                        {edited?buttoneditableClient(isActiveEdition,editionMode):""}
                        {/* <button style={isActiveEdition?{opacity:".7"}:{opacity:"1"}} onClick={editionMode}></button> */}
                    </div>
                </section>
                <section className="actions-section">
                    <div>

                        {/* {isActiveEdition?EditionModeActions(handleSave,desactivateEdition):NotEditionModeActions(clientId,slug)} */}
                        {edited?buttoneditable(activateDeleteAlert,isActiveEdition,handleSave,desactivateEdition,clientId,slug):""}
                    </div>
                </section>
                <Link to={BILL}>
                    <span>
                        <img src={returntosvg } alt=""/>
                    </span>
                        Volver a la lista de notas
                </Link>
            </div>

            {loadingFetchedDetails?
                <></>
            :clientDetails?
                <>
                    <ModalBackground isActive={isActiveDeleteAlert} closeByBackground={null}>
                        <ModalAlert title={"¡Atención!"} message={`¿Estas seguro/a que deseas finalizar la nota de el cliente ${clientDetails.name} ?. No se podra editar ningun dato. `} closeByButton={desactivateDeleteAlert} handleSave={handleDelete} handleCancelation={handleCancelation}/>
                    </ModalBackground>
                    <ModalBackground isActive={isActiveDeleteProducto} closeByBackground={null}>
                        <ModalAlert title={"¡Atención!"} message={`¿Estas seguro/a que deseas ELIMINAR el producto ${deleteProduct} ?.`} closeByButton={desactivateDeleteProducto} handleSave={handleDeleteProduct} handleCancelation={handleCancelationProduct}/>
                    </ModalBackground>
                    <ModalBackground isActive={isActiveAddProducto} closeByBackground={null}>
                        <ModalSearcher title={"Agregar Productos"} closeByButton={desactivateAddProducto} handlesearch={handlesearch} handleAddProduct={handleAddProduct}>
                            <div className="h-[77%] mt-auto mb-0">
                                <TableProduct head={ESTranslation.TABLE_HEAD.add_product} body={processedProduct} error={fetchedProductErrors}/>
                            </div>
                        </ModalSearcher>
                    </ModalBackground>
                </>
            :
                <></>
            }
            
            <Loader isActive={loadingFetchedDetails}/>
            <Loader isActive={loadingClientUpdate}/>
        </>
    )
}

export default BillDetail