import Axios from 'axios';

const post = async (props,data2) => {
    const url = props
    const config = {
        url: url,
        method: 'post',
        withCredentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        data: data2,
    }
    // let _r = await fetch(url).then(response=>response.ok?response.json():Promise.reject(response)).then(json=>json).catch(err=> console.log(err))
    let result = await Axios(config)
    return(
        result.data
    )
}

export default post