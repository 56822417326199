import React from 'react'
import "../../assets/css/loader.css"
import { createPortal } from 'react-dom'

const ACTIVE_LOADER_STYLES={
    display:"flex"
}
const INACTIVE_LOADER_STYLES={
    display:"none"
}

const Loader=({isActive})=>{

    return createPortal(
        <div style={isActive?ACTIVE_LOADER_STYLES:INACTIVE_LOADER_STYLES}>  
            <div className='modal-loader'>
                <span className="loader" id="Loader">
                    <svg viewBox="25 25 50 50">
                        <circle r="20" cy="50" cx="50"></circle>
                    </svg>
                </span>
            </div>
        </div>,document.getElementById("loader")
    )
}

export default Loader

