const FILTER={
    client_page:[
        {
            name:"Nuevos",
            id:"byrecents",
            directions:[]
        },  
        {
            name:"Numero de pedidos",
            id:"byorders",
            directions:[
                {
                    name:"Menor a mayor",
                    id:"ascendant"
                },
                {
                    name:"Mayor a menor",
                    id:"descendant"
                }
            ]
        },
        {
            name:"Ordenar",
            id:"byname",
            directions:[
                {
                    name:"A a Z",
                    id:"ascendant"
                },
                {
                    name:"Z a A",
                    id:"descendant"
                }
            ]
        },
    ],
    product_page:[
        {
            name:"Publicados",
            id:"bypublished",
            directions:[]
        },  
        {
            name:"Ocultos",
            id:"byhidden",
            directions:[]
        },
        {
            name:"Existencia",
            id:"byname",
            directions:[
                {
                    name:"Mayor a menor",
                    id:"ascendant"
                },
                {
                    name:"Menor a mayor",
                    id:"descendant"
                }
            ]
        },
        {
            name:"Ordenar",
            id:"byname",
            directions:[
                {
                    name:"A a Z",
                    id:"ascendant"
                },
                {
                    name:"Z a A",
                    id:"descendant"
                }
            ]
        },
    ],
    transactions_page:[
        {
            name:"Fecha",
            id:"bydate",
            directions:[]
        },
        {
            name:"Tipo de movimiento",
            id:"bytype",
            directions:[]
        },
        {
            name:"Monto",
            id:"byamount",
            directions:[]
        }
        
    ]
}

const TABLE_HEAD={
    users_page:["Nombre y Apellido","Nombre de Usuario",""],
    product_page:["Codigo","Nombre del producto","Existencia",""],
    bill_page:["Numero","Nombre","Estatus",""],
    add_product:["Disponible","Nombre","Agregar"],
    bill_product:["Nombre","(Disponible)-Cantidad","Eliminar"],
    transactions_page:["Fecha","Descripción","Tipo de movimiento","Monto"]
}

const FORM={
    add_users:{
        title:"Crear Usuario",
        inputs:[""],
        actions:["Descartar","Guardar"]
    },
    add_product:{
        title:"Crear Producto",
        inputs:[""],
        actions:["Descartar","Crear"]
    },
    add_bill:{
        title:"Crear Nota de Entrega",
        inputs:[""],
        actions:["Descartar","Crear"]
    }
// const ALERTS={

// }

}

export default {FILTER,TABLE_HEAD,FORM}