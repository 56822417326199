import { useEffect } from "react"
import { useRef } from "react"
import alertsvg from "../../assets/images/intencol/alert.svg"


const AddProductForm = ({formState,editable}) => {
    let {get,set,errors,clear}=formState

    let $form=useRef(null)
    let $name=useRef(null)
    let $code=useRef(null)
    let $stock=useRef(null)

    useEffect(()=>{
      if(editable){
        $name.current.disabled=false
        $code.current.disabled=false
      }
      if(!editable){
        $name.current.disabled=true
        $code.current.disabled=true
        $stock.current.disabled=true
      }

    },[editable])

    useEffect(()=>$form.current.reset(),[clear])

  return (
    <form className='form-addclient' ref={$form}>
        <span>
          <label htmlFor="Fullname">Nombre del Producto:</label>
          <input type="text" id="Fullname" autoComplete="off" ref={$name}  defaultValue={get.name} onChange={(e)=>{
              let {target:{value}}=e
              let {name,...props}=get

              set({name:value,...props})
          }}
          />
          <img style={
            errors.fullname?  
              {
                display:"flex"
              }
            :
              {
                display:"none"
              }
          } src={alertsvg} alt=""/>
        </span>
        <span>
            <label htmlFor="IdentityDocumentSerial">Codigo:</label>
            <input type="text" id="IdentityDocumentSerial" autoComplete="off" ref={$code} defaultValue={get.code} onChange={(e)=>{
              let {target:{value}}=e
              let {code,...props}=get

              set({code:value,...props})
          }}/>
            <img style={
              errors.code?  
                {
                  display:"flex"
                }
              :
                {
                  display:"none"
                }
              } src={alertsvg} alt=""/>
        </span>
        <span>
            <label htmlFor="PhoneNumber">Inventario</label>
            <input type="number" id="PhoneNumber" autoComplete="off" ref={$stock} defaultValue={get.stock} onChange={(e)=>{
              let {target:{value}}=e
              let {stock,...props}=get

              set({stock:value,...props})
          }}/>
            <img style={
              errors.stock?  
                {
                  display:"flex"
                }
              :
                {
                  display:"none"
                }
              } src={alertsvg} alt=""/>
        </span>
    </form>
  )
}

export default  AddProductForm