import { useState } from 'react'

export const useModal = (active=false) => {
    let [isActive,setIsActive]=useState(active)

    const activateModal=()=>setIsActive(true)
    const desactivateModal=()=>setIsActive(false)

    return [isActive,activateModal,desactivateModal]
}

export default useModal