import Axios from 'axios';

const put = async (url,data) => {
    const config = {
        url: url,
        method: 'put',
        withCredentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    }
    // let _r = await fetch(url).then(response=>response.ok?response.json():Promise.reject(response)).then(json=>json).catch(err=> console.log(err))
    let result = await Axios(config)
    return(
        result.data
    )
}

export default put