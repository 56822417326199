import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import ModalAlert from "../../components/modal/modal_alert";
import ModalBackground from "../../components/modal/modal_background";
import useEdition from "../../hooks/useEdition";
import useModal from "../../hooks/useModal";
import returntosvg from "../../assets/images/inicia/arrow-orange.svg"
import useFetch from "../../hooks/useFetch";
import Loader from "../../components/modal/loader";
import { PRODUCT_ENDPOINTS } from "../../services/api.config";
import put from "../../helpers/put";
import { PRODUCT } from "../../routes/paths";
import AddProductForm from "../../components/form/add_product_form";
import adelete from "../../helpers/adelete";
const DELETE_CLIENT_BUTTON_STYLES={
    backgroundColor:"#FF5959",
    width:"95%"
}
const EDIT_CLIENT_BUTTONS_STYLES={
    backgroundColor:"#00ade5",
    width:"43%",
    marginLeft:"auto",
    marginRight:"auto"
}


const NotEditionModeActions=(id,slug)=>{

    return (
        <>
        {/* <Link style={MOVEMENTS_CLIENT_BUTTON_STYLES} state={{id}} to={`${CLIENT}/${slug}/transactions`} >
                Ver movimientos
        </Link> */}
        </>
    )
}
const EditionModeActions=(update,cancel)=>{

    const handleCancelation=()=>cancel()
    const handleSave=()=>update()

    return (
        <>
            <button style={EDIT_CLIENT_BUTTONS_STYLES} onClick={handleCancelation}>
                Cancelar
            </button>
            <button style={EDIT_CLIENT_BUTTONS_STYLES} onClick={handleSave}>
                Guardar
            </button>
        </>
    )
}

const ProductDetail=()=>{
    const {slug}=useParams();
    const {id:clientId}=useLocation().state;
    let _initialFormState={
        name:'',
        code:'',
        stock:'',
    }
    let _initialFormErrorState={
        name:false,
        code:false,
        stock:false,
    }
    let navigate = useNavigate();
    let [isActiveEdition,activateEdition,desactivateEdition]=useEdition(false)
    let [isActiveDeleteAlert,activateDeleteAlert,desactivateDeleteAlert]=useModal(false)
    let [clientForm,setClientForm]=useState(_initialFormState)
    let [clientFormError,setClientFormError]=useState(_initialFormErrorState)
    let {data:fetchedDetails,loading:loadingFetchedDetails}=useFetch(`${PRODUCT_ENDPOINTS.getOne(clientId)}`)
    let [clientDetails,setClientDetails]=useState(null)
    let [loadingClientUpdate,setLoadingClientUpdate]=useState(false)
    let [stock, setStock]=useState()
    let [stockType, setStockType]=useState()
    let [stockValue, setStockValue]=useState()
    let $stock=useRef(null)

    useEffect(()=>{
        if(fetchedDetails){
            let {code,name,stock}=fetchedDetails.data
            let _initialFormState={
                name:name,
                code:code,
                stock:stock,
            }

            setClientForm(_initialFormState)
            setClientDetails(fetchedDetails.data)
        }
    },[fetchedDetails])
    const editionMode=()=>{
        let _oldState=isActiveEdition
            _oldState?desactivateEdition():activateEdition()
    }
    const handleSave=()=>{
        let _errors={}
        for (const key in clientForm) {
            if (Object.hasOwnProperty.call(clientForm, key)) {
                const field = clientForm[key];
                // Fields validation
                if(typeof field === 'string') {
                    field.trim()===''?_errors[key]=true:_errors[key]=false
                }
            }
        }

        let _anyError=[...new Set([...Object.values(_errors)])]
        if(_anyError.length===1 && _anyError[0]===false){
            //Put
            let _data={
                name:clientForm.name,
                code:clientForm.code,
            }
            let _updateClientPromise=put(PRODUCT_ENDPOINTS.update(clientId),_data)

                setLoadingClientUpdate(true)
                _updateClientPromise
                .then(response=>{
                    if(response.message==="all is fine"){
                        let _updatedClient={
                            name:clientForm.name,
                            code:clientForm.code,
                            stock:clientDetails.stock,
                        }
                        let _newFormState={
                            name:clientForm.name,
                            code:clientForm.code,
                            stock:clientDetails.stock,
                        }
                            setClientDetails(_updatedClient)
                            setClientForm(_newFormState)
                        desactivateEdition()
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
                .finally(()=>{
                   setLoadingClientUpdate(false)
                })
            }
    }
    const handleSaveStock=()=>{
        //Put
        let _data={
            type:stockType,
            stock:parseFloat(stock),
        }
        let _updateClientPromise=put(PRODUCT_ENDPOINTS.updateStock(clientId),_data)
                setLoadingClientUpdate(true)
                _updateClientPromise
                .then(response=>{
                    if(response.message==="all is fine"){
                        setStockValue(0)
                        let _newFormState={
                            name:clientDetails.name,
                            code:clientDetails.code,
                            stock:response.data,
                        }
                            setClientDetails(_newFormState)
                            setClientForm(_newFormState)
                        $stock.current.value = "";
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
                .finally(()=>{
                    setLoadingClientUpdate(false)
                })
    }
    const handleCancelation=()=>desactivateDeleteAlert()
    const handleDelete=()=>{
        let _updateClientPromise=adelete(PRODUCT_ENDPOINTS.delete(clientId))
        setLoadingClientUpdate(true)
        _updateClientPromise
        .then(response=>{
            if(response.message==="all is fine"){
                return navigate(PRODUCT)
            }
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            setLoadingClientUpdate(false)
        })
        // DELETE
        desactivateDeleteAlert()
    }
    
    return (
        <>
            <div className="client-detail-grid">
                <section className="client-info-section">
                    <p>Información de producto:</p>
                    <div className="box-shadow">
                        {loadingFetchedDetails
                            ?
                                <></>
                            :
                                <AddProductForm formState={{get:clientForm,set:setClientForm,errors:clientFormError}} editable={isActiveEdition}/>
                        }
                        <button style={isActiveEdition?{opacity:".7"}:{opacity:"1"}} onClick={editionMode}></button>
                    </div>
                </section>
                <section className="client-summary-section">
                </section>
                <section className="">
                    <p  className="text-sm ml-4 mb-1">Ajustar Inventario</p>
                    <div className="py-4 box-shadow mx-2 rounded-xl flex justify-around items-center">
                        <div>
                            <input type="number" ref={$stock} className="rounded-lg border-solid shadow mt-1 mb-3 h-fit text-xs px-2 py-1 w-60" onChange={(e)=>{
                                let {target:{value}}=e
                                setStock(value)
                                }}/>
                            <div className="flex justify-center">
                                <li>
                                    <input className="hidden peer" type="radio" name="type" id="increase"/>
                                    <label htmlFor="increase" className="text-in bg-intencol3 opacity-50 text-white px-4 py-1 rounded-bl-xl  rounded-tl-xl peer-checked:opacity-100" onClick={(e)=>{setStockType('1')}} >Aumentar</label>
                                </li>
                                <li>
                                    <input className="hidden peer" type="radio" name="type" id="decrease"/>
                                    <label htmlFor="decrease" className="text-in bg-intencol3 opacity-50 text-white px-4 py-1 rounded-br-xl  rounded-tr-xl peer-checked:opacity-100" onClick={(e)=>{setStockType('2')}} >Disminuir</label>
                                </li>
                            </div>
                        </div>
                        <button className="rounded-full  text-text border-intencol2 border-solid border-2  px-7 py-1 h-fit" onClick={handleSaveStock}>Ajustar</button>
                    </div>
                </section>
                <section className="actions-section">
                    <div>
                        {isActiveEdition?EditionModeActions(handleSave,desactivateEdition):NotEditionModeActions(clientId,slug)}
                        
                        <button style={DELETE_CLIENT_BUTTON_STYLES} onClick={activateDeleteAlert} >
                            Eliminar producto
                        </button>
                    </div>
                </section>
                <Link to={PRODUCT}>
                    <span>
                        <img src={returntosvg } alt=""/>
                    </span>
                        Volver a la lista de producto
                </Link>
            </div>

            {loadingFetchedDetails
                ?
                    <></>
                :
                clientDetails
                    ?
                        <ModalBackground isActive={isActiveDeleteAlert} closeByBackground={null}>
                            <ModalAlert title={"¡Atención!"} message={`¿Estas seguro/a que deseas finalizacion la nota de ${clientDetails.name} ?. Ya no podras editar la nota. `} closeByButton={desactivateDeleteAlert} handleSave={handleDelete} handleCancelation={handleCancelation}/>
                        </ModalBackground>
                    :
                        <></>
            }
            
            <Loader isActive={loadingFetchedDetails}/>
            <Loader isActive={loadingClientUpdate}/>
        </>
    )
}

export default ProductDetail