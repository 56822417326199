import React from 'react'

const SaveButton=({handle})=>{
    const STYLES={
        backgroundColor:"#00ade5",
        color:"white"
    }

    return (
        <button style={STYLES} onClick={handle}>
            Confirmar
        </button>
    )
}

const CancelButton=({handle})=>{
    const STYLES={
        backgroundColor:"transparent",
        color:"#00ade5"
    }

    return (
        <button style={STYLES} onClick={handle}>
            Cancelar
        </button>
    )
}

const ModalAlert = ({title,message,closeByButton,handleSave,handleCancelation}) => {
  return (
    <div className='modal-alert'>
        <span className='modal-alert-tittle'>
            <h3>{title}</h3>
        </span>
        <button className='modal-alert-close' onClick={closeByButton}></button>
        <p className='modal-alert-message'>{message}</p>
        <div className='modal-alert-actions'>
            <SaveButton handle={handleSave}/>
            <CancelButton handle={handleCancelation}/>
        </div>
    </div>
  )
}

export default ModalAlert