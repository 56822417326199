import { useEffect, useState } from "react";
import get from "../helpers/get";

const useFetch=(url)=>{
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    useEffect(() => {
      setLoading(true);
        get(url)
        .then((response) => {
          setData(response);
        })
        .catch((err) => {
            if(err.response){
                if(err.response.status>=400 && err.response.status<500 ){
                    let _err={
                      message:'Error al requerir los datos',
                      status:err.response.status
                    }
                  setError(_err)
                }
                if(err.response.status>=500){
                  let _err={
                    message:'Error en el servidor',
                    status:err.response.status
                  }
                setError(_err)
              }
            }
            if(err.request){
              let _err={
                message:'Error de red',
                status:null
              }
              setError(_err)
            }
        })
        .finally(() => {
          setLoading(false);
        });
    }, [url]);
    

    return { data, loading, error};
}
    
  export default useFetch

