import { Route, Routes } from 'react-router';
import { BILL, BILL_DETAIL, HOME, LOGOUT,  PRODUCT,  PRODUCT_DETAIL,  USER, USERS, USERS_DETAIL} from './routes/paths';
import Home from './pages/home/home';
import PublicRouter from './components/router/publicRouter';
import PrivateRouter from './components/router/privateRouter';
import User from './pages/user/user';
import Redirection from './helpers/Redirection';
import P404 from './pages/404/404';
import Logout from './pages/logout/logout';
import Users from './pages/users/users';
import UsersDetail from './pages/users/usersDetail';
import Product from './pages/product/product';
import ProductDetail from './pages/product/productDetail';
import Bill from './pages/bill/bill';
import { BILL_ENDPOINTS } from './services/api.config';
import BillDetail from './pages/bill/billDetail';

function App() {
  return (
    <Routes>
      <Route path={HOME} element={<PublicRouter/>}>
          <Route index path={HOME} element={<Home/>} />
      </Route>
      <Route path={USER} element={<PrivateRouter/>}>
        <Route path={USER} element={<User/>}> 
          <Route path="" element={<Redirection/>}/>
          <Route path={PRODUCT} element={<Product/>}/>  
          <Route path={PRODUCT_DETAIL} element={<ProductDetail/>}/> 
          <Route path={BILL} element={<Bill/>}/>  
          <Route path={BILL_DETAIL} element={<BillDetail/>}/> 
          <Route path={USERS} element={<Users/>}/> 
          <Route path={USERS_DETAIL} element={<UsersDetail/>}/> 
        </Route>
        <Route path={LOGOUT} element={<Logout/>}/>
      </Route>
      <Route path='*' element={<Redirection/>} />
    </Routes>
  );
}

export default App;
