import ModalBackground from "../../components/modal/modal_background"
import useModal from "../../hooks/useModal"
import addclientsvg from "../../assets/images/intencol/add-client-green.svg"
import ModalForm from "../../components/modal/modal_form"
import AddClientForm from "../../components/form/add_client_form"
import { useEffect, useState} from "react"
import Loader from "../../components/modal/loader"
import Table from "../../components/container/table"
import { Link, Navigate} from "react-router-dom"
import { PRODUCT, USERS } from "../../routes/paths"
import useFetch from "../../hooks/useFetch"
import { USER_ENDPOINTS } from "../../services/api.config"
import post from "../../helpers/post"
import { replaceEmptySpacesForHyphens } from "../../utils/formatText"
import ESTranslation from "../../ES/ESTranslation"
import get from "../../helpers/get"


const ADD_CLIENT_BUTTON_STYLES={
    marginLeft:'auto',
    cursor:'pointer'
}

const EDIT_CLIENT_BUTTON_STYLES={
    color:'#00ade5',
    cursor:'pointer'
}
const Height={
    height:'90%',
}

const preprocessTableBodyData=(data)=>{
    data=data || []
    const actionButtons=(id,slug)=>{

        return (
            <>
                <Link style={EDIT_CLIENT_BUTTON_STYLES} state={{id}} to={`${USERS}/${id}`} >
                    Ver o editar
                </Link>
            </>
        )
    }
    let _processedData=[...data].map(row=>{
        let {id,name,username}=row
            let  _slug=replaceEmptySpacesForHyphens(name).toLowerCase()
                
            let _newData={
                name,
                username,
                actions:actionButtons(id,_slug)
            }

            return _newData
    })
        
    return _processedData
        
}

const Users=()=>{
    let _initialFormState={
        fullname:'',
        username:'',
        password:'',
    }
    let _initialFormErrorState={
        fullname:false,
        username:false,
        password:false,
    }
    let [isActiveAddClientModal,activateAddClientModal,desactivateAddClientModal]=useModal(false)
    let {data:fetchedClients,loading:loadingFetchedClients,error:fetchedClientsErrors}=useFetch(USER_ENDPOINTS.getAll())
    let [clientForm,setClientForm]=useState(_initialFormState)
    let [clientFormError,setClientFormError]=useState(_initialFormErrorState)
    let [toggleFormClear,setToggleFormClear]=useState(false)
    let [clients,setClients]=useState(null)
    let [processedClients,setProcessedClients]=useState(null)
    let [loadingClientCreation,setLoadingClientCreation]=useState(false)
    let [MessageNew,setMessageNew]=useState("")
    

        useEffect(()=>{
            if(fetchedClients)setClients(fetchedClients.data)
        },[fetchedClients])    

        useEffect(()=>{
            if(clients){
                let _processedData=preprocessTableBodyData(clients)
                    setProcessedClients(_processedData)  
            }
        },[clients])

    const handleSave=async()=>{
        let _errors={}

        for (const key in clientForm) {
            if (Object.hasOwnProperty.call(clientForm, key)) {
                const field = clientForm[key];
                // Fields validation
                field.trim()===''?_errors[key]=true:_errors[key]=false
            }
        }
        setClientFormError(_errors)
        let _anyError=[...new Set([...Object.values(_errors)])]
            if(_anyError.length===1 && _anyError[0]===false){
                //Post
                let _data={
                    name:clientForm.fullname,
                    username:clientForm.username,
                    password:clientForm.password,
                }
                let _createClientPromise=post(USER_ENDPOINTS.create(),_data)
                    setLoadingClientCreation(true)
                    _createClientPromise
                    .then(response=>{
                        if(response.message==="all is fine"){
                            let prueba = get(USER_ENDPOINTS.getAll())
                            prueba
                            .then((response) => {
                                setClients(response.data);
                                setClientForm(_initialFormState)
                                setToggleFormClear(prevState=>!prevState)
                                desactivateAddClientModal()
                            })
                        }
                        if(response.message === 'empty data'){
                            setMessageNew('Hay Dato vacío')
                        }
                        if(response.message === 'user exist'){
                            setMessageNew('El Usuario ya esta en uso')
                        }
                        if(response.message === 'username'){
                            setMessageNew('Nombre de usuario debe tener minimo 5 Caracteres y contener solo numero y letras')
                        }
                        if(response.message === 'password'){
                            setMessageNew('La contraseña debe tener minimo 7 Caracteres')
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                        desactivateAddClientModal()
                    })
                    .finally(()=>{
                        setLoadingClientCreation(false)
                    })

            }
            // Error filling the fields (message)
    }

    const handleCancelation=()=>{
        setClientForm(_initialFormState)
        setToggleFormClear(prevState=>!prevState)
    }


    return (
        <>
            <div className="container">{/*componente container*/}
                <div className="content-container box-shadow clients-table">{/*componente container*/}
                    <section className="clients-table-body-section" style={Height}>
                        <Table head={ESTranslation.TABLE_HEAD.users_page} body={processedClients} error={fetchedClientsErrors} columns="40% 40% 20%"/>
                    </section>
                    <section className="clients-table-footer-section">
                        <div>
                            <button style={ADD_CLIENT_BUTTON_STYLES} onClick={activateAddClientModal}>
                                <span>
                                    <img src={addclientsvg} alt=""/>
                                </span>
                                Agregar Usuario
                            </button>
                        </div>
                    </section>
                </div>
            </div>

            <ModalBackground isActive={isActiveAddClientModal} closeByBackground={desactivateAddClientModal}>
                <ModalForm title={ESTranslation.FORM.add_users.title} closeByButton={desactivateAddClientModal} handleSave={handleSave} handleCancelation={handleCancelation} alert={MessageNew}>
                    <AddClientForm formState={{get:clientForm,set:setClientForm,errors:clientFormError,clear:toggleFormClear}} editable={true}/>
                </ModalForm>   
            </ModalBackground> 

            <Loader isActive={loadingFetchedClients}/>
            <Loader isActive={loadingClientCreation}/>
                
        </>
    )

}

export default Users