import { useState } from 'react'

export const useEdition = (active=false) => {
    let [isActive,setIsActive]=useState(active)

    const activateEdition=()=>setIsActive(true)
    const desactivateEdition=()=>setIsActive(false)

    return [isActive,activateEdition,desactivateEdition]
}

export default useEdition