import { createContext, useCallback, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types"

const KEYSTOREGE = 'MY_AUTH_INTENCOL';
const AuthContext = createContext()

const AuthContextProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(window.localStorage.getItem({KEYSTOREGE}) ?? false)
    // const [isAuthenticated, setIsAuthenticated] = useState(true)
    
    const login = useCallback( function(){
        window.localStorage.setItem({KEYSTOREGE}, true)
        setIsAuthenticated(true)
    },[])

    const logout = useCallback(function (){
        window.localStorage.removeItem({KEYSTOREGE})
        setIsAuthenticated(false)
    },[])

    const value = useMemo(
        () => ({
            login,
            logout,
            isAuthenticated
        }), 
        [login,logout,isAuthenticated]
    )
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthContextProvider.proptype = {
    children: PropTypes.object
}

const UserAuthContext = () =>{
    return useContext(AuthContext)
}


export {AuthContext}
export {UserAuthContext}
export default AuthContextProvider