
const ALL_HYPHENS_REGEX=/-/g
const ALL_EMPTYSPACES_REGEX=/\s+/g

const replaceHyphensForEmptySpaces=(expression)=>{
    let _newExpression=expression.replace(ALL_HYPHENS_REGEX," ")
        return _newExpression
}

const replaceEmptySpacesForHyphens=(expression)=>{
    let _newExpression=expression.replace(ALL_EMPTYSPACES_REGEX,"-")
    return _newExpression
}


export {replaceEmptySpacesForHyphens,replaceHyphensForEmptySpaces}